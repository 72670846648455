<template>
  <router-view></router-view>

</template>

<script>
export default {
  name: "App",
  components: {
  },
};
</script>

<style>
body{
background: #f1f1f1;
}
</style>
