import { createApp } from 'vue'
import App from './App.vue'

import router from './router'

// 重置样式
import "@/assets/reset.css"

import 'lib-flexible' // 移动端适配

createApp(App).use(router).mount('#app')
