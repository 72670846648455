import {
  createRouter,
  createWebHashHistory,
} from "vue-router";
const router = createRouter({
  history: createWebHashHistory(),
  routes: [
  
    {
      path: "/detail/:id?",
      component: () => import("@/pages/Detail"),
      name: "detail",
    },
 
    {
      path: "/home",
      component: () => import("@/pages/Home"),
      name: "home",
    },
    {
      path: "/",
      redirect: "/home",
    },
  ],

  scrollBehavior(to, from, savedPosition) {
    // console.log(savedPosition);
    return { top: 0 };
  },
});
export default router;
